import React, { useEffect, useState } from "react";
import { Box, Typography, Paper, Grid, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate,  useSearchParams } from "react-router-dom";
import { GlobalWorkerOptions } from "pdfjs-dist";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import MetaInfo from "../MetaInfo";
import DisplaySeries from "./DisplaySeries";
import FileInfoSection from "./FileInfoSection";
import "./InfoSection.css";
import {
  getStudyInfo,
  getScansDetails,
  getReportURL,
  getAttachedReport,
  getThumbnails,
} from "../../store/actions/records.action";
import {
  signoutAction
}from "../../store/actions"
import { patientSelector } from "../../store/slice/patient.slice";
import { removeSpecialCharacters } from "../../utilities/common";
import styled from "styled-components";
import Breadcrumb from "../../components/Breadcrumb";
import {
  ShimmerContentBlock,
  ShimmerCategoryItem,
} from "react-shimmer-effects";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import RecordItem from "../layout/RecordItem/RecordItem";
import {
  StyledImage,
  StyledModal,
  StyledModalContent,
  StyledCloseButton,
} from "./style.components";

const TopHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 24px;
  font-color: "#000";
`;

const workerUrl = `//${window.location.host}/pdf.worker.min.js`;
GlobalWorkerOptions.workerSrc = workerUrl;

const InfoSection = () => {
  const [dicomInfo, setDicomInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const location = useLocation();
  const [loadingAttachedReport, setLoadingAttachedReport] = useState(true);
  const [scans, setScans] = useState([]);
  const thumbnails = useSelector((state) => state?.thumbnail?.thumbnails);
  const [open, setOpen] = useState(false);
  const { selectedPatientId } = useSelector(patientSelector);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [attachedReport, setAttachedReport] = useState([]);
  const { studyFhirId } = location.state || {};
  const [loadingScanDetails, setLoadingScanDetails] = useState(true);
  const navigate = useNavigate();
 const [storeData, setStoreData] = useState(null);
 const [searchParams] = useSearchParams();

 const containsOpenShare = location.pathname.includes("open-share");
 const containsOpenProfile = location.pathname.includes("profile");

  const fetchStudyDetails = async () => {
    setLoading(true);
    try {
      const action = await dispatch(getStudyInfo({ fhir_id: studyFhirId }));
      const response = action.payload;
      if (response && response.status === "success") {
        const responseData = action?.payload?.data || [];
        setDicomInfo(responseData);
      } else {
        console.error("Failed to fetch study info:", action.error);
      }
    } catch (error) {
      console.error("Failed to fetch study info:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchScanDetails = async () => {
    setLoadingScanDetails(true);
    try {
      const scansAction = await dispatch(
        getScansDetails({ fhir_id: studyFhirId })
      );
      const response = scansAction.payload;
      if (scansAction && response.status === "success") {
        const responseScansData = scansAction?.payload?.data || [];
        setScans(responseScansData);
      } else {
        console.error("Failed to fetch scan info:", scansAction.error);
      }
    } catch (error) {
      console.error("Failed to fetch scan info:", error);
    } finally {
      setLoadingScanDetails(false);
    }
  };

  const fetchThubnailImages = async (responseScansData) => {
    if (!responseScansData?.length) return;
    const filePaths = responseScansData
      .filter((item) => item?.file_path)
      .map((item) => ({
        file_path: item.file_path,
        fhir_id: item.id,
      }));
    filePaths.length &&
      dispatch(
        getThumbnails({
          file_objects: filePaths,
        })
      );
  };

  const fetchAttachedReport = async () => {
    setLoadingAttachedReport(true);
    try {
      const attachedReports = await dispatch(
        getAttachedReport({
          fhir_id: studyFhirId,
          patient_id: selectedPatientId,
        })
      );
      const response = attachedReports.payload;
      if (response && response.status === "success") {
        const responseScansData = response?.data || [];
        setAttachedReport(responseScansData);
        fetchThubnailImages(responseScansData);
      } else {
        console.error("Failed to fetching attached report:", response.error);
      }
    } catch (error) {
      console.error("Failed to fetch attached report:", error);
    } finally {
      setLoadingAttachedReport(false);
    }
  };
  useEffect(() => {
    if(studyFhirId && selectedPatientId) {
      fetchStudyDetails();
      fetchScanDetails();
      fetchAttachedReport();
    }
  }, [selectedPatientId, studyFhirId ]);

  useEffect(() => {
    if(!studyFhirId && selectedPatientId) {
      navigate(`/records?patient_id=${selectedPatientId}`)
    }
  }, [])

  const handleBackNavigation = (event) => {
    event.preventDefault();
    navigate(-1);
  };

  const breadCrubLinks = [
    {
      label: "Home",
      href: "/",
    },
    {
      label: "Health Data",
      onClick: handleBackNavigation,
    },
    {
      label: "Scan",
    },
  ];

  const fetchReportUrl = async (filePath) => {
    try {
      const payload = {
        file_path: filePath,
      };
      const response = await dispatch(getReportURL({ payload: payload }));
      if (response?.payload?.status === "success") {
        return response?.payload?.data;
      } else {
        return "";
      }
    } catch (error) {
      console.log("error occured while fetching url");
      return "";
    }
  };

  const handleListItemClick = async (e, record) => {
    e.stopPropagation();
    const url = await fetchReportUrl(record?.file_path);
    url && setSelectedRecord({ ...record, url: url });
    setOpen(true);
  };

  const renderAttachedReport = () => (
    <>
      {!loadingAttachedReport ? (
        attachedReport.length ? (
          <Grid container gap={3} mt={4}>
            {attachedReport.map((item, index) => {
              return (
                <Box
                  style={{ width: 140, color: "#000" }}
                  display="flex"
                  flexDirection="column"
                >
                  <RecordItem
                    reportType={"report"}
                    fetchUrlForFile={(e) => handleListItemClick(e, item)}
                    setStoreData={setStoreData}
                    menuItems={[]}
                    row={item}
                    index={index}
                    thumbnailData={thumbnails}
                    isNotShowDropdown={true}
                    isShowDropdown={true}
                    width={135}
                  />
                </Box>
              );
            })}
          </Grid>
        ) : (
          <Box
            mt={6}
            mb={2}
            display={"flex"}
            alignItems="center"
            sx={{ color: "#000", fontSize: "13px" }}
            justifyContent="center"
          >
            Records not Attached.{" "}
          </Box>
        )
      ) : (
        <Box m={2}>
          <ShimmerContentBlock
            title
            text
            cta
            thumbnailWidth={100}
            thumbnailHeight={100}
          />
        </Box>
      )}
    </>
  );

  const backToLogin = async() => {
    if (searchParams.has("accessCode")) {
      searchParams.delete("accessCode");
    }
    await dispatch(signoutAction());
    navigate("/login");
  };

  return (
    <Box m={5} mt={0}>
      {!containsOpenShare && <Breadcrumb links={breadCrubLinks} />}
      <Box display="flex" justifyContent="space-between">
        <TopHeader>
          {containsOpenShare && containsOpenProfile ? (
            <>
              <ArrowBackIosIcon sx={{ cursor: "pointer", color: "#0099CC" }} onClick={() => navigate(-1)}/>
            </>
          ) : null}
          Studies
        </TopHeader>
        {containsOpenShare && (
          <Button
            sx={{
              color: "#FFF",
              background: "#7ABA56",
              padding: "0px 10px",
              "&:hover": {
                background: "#7ABA56",
                color: "#FFF",
              },
              marginRight: 1,
            }}
            onClick={backToLogin}
          >
            Login to see other patients
          </Button>
        )}
      </Box>
      <Box
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        alignItems="stretch"
      >
        <Grid item>
          <Paper
            className="box-wrapper"
            sx={{
              background: "#EBF7FB",
              borderRadius: {
                xs: "1rem 1rem 0 0",
                md: "1rem 0 0 1rem",
              },
              paddingBottom: 2,
              display: "flex",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={2}
              p={2}
              className="info-header"
              sx={{
                borderTopLeftRadius: "1rem",
                borderTopRightRadius: { xs: "1rem", sm: 0 },
              }}
            >
              <Typography variant="h6" sx={{ color: "#FFF" }}>
                {!loading ? (
                  dicomInfo?.study_info?.patient_name ? (
                    removeSpecialCharacters(dicomInfo?.study_info?.patient_name)
                  ) : (
                    <div style={{ visibility: "none" }}>Test</div>
                  )
                ) : (
                  <div style={{ visibility: "none" }}>Test</div>
                )}
              </Typography>
            </Box>
            <Box>
              {!loading ? (
                dicomInfo ? (
                  <FileInfoSection recordInfo={dicomInfo?.study_info} />
                ) : null
              ) : (
                <Box
                  m={2}
                  sx={{
                    padding: "1rem",
                    borderRadius: "1rem",
                    background: "#FFF",
                  }}
                >
                  <ShimmerCategoryItem
                    hasImage
                    imageType="circular"
                    imageWidth={100}
                    imageHeight={80}
                    title
                  />
                </Box>
              )}
              {attachedReport.length ? (
                <Box
                  display="flex"
                  m={2}
                  p={2}
                  flexDirection="column"
                  sx={{ background: "#FFF", borderRadius: 4 }}
                >
                  <Box className="series-text" pl={2}>
                    Attached Report{" "}
                    {attachedReport.length ? `(${attachedReport.length})` : ""}
                    {renderAttachedReport()}
                  </Box>
                </Box>
              ) : null}
              <Box
                display="flex"
                m={2}
                p={2}
                flexDirection="column"
                sx={{ background: "#FFF", borderRadius: 4 }}
              >
                <Box className="series-text" pl={2}>
                  Series {scans?.length ? `(${scans.length})` : ""}
                </Box>
                {!loadingScanDetails ? (
                  <Box display="flex">
                    <DisplaySeries recordInfo={scans} />
                  </Box>
                ) : (
                  <Box m={2}>
                    <ShimmerContentBlock
                      title
                      text
                      cta
                      thumbnailWidth={400}
                      thumbnailHeight={200}
                    />
                  </Box>
                )}
              </Box>
            </Box>
          </Paper>
        </Grid>
        <Grid item>
          <MetaInfo
            recordInfo={dicomInfo}
            scans={scans}
            sx={{ width: "100%" }}
            loading={loading}
          />
        </Grid>
      </Box>
      <StyledModal open={open} onClose={() => {}}>
        <StyledModalContent>
          <StyledCloseButton onClick={() => setOpen(false)}>
            &times;
          </StyledCloseButton>
          {selectedRecord &&
            (["image/jpeg", "image/png"].includes(
              selectedRecord.content_type
            ) || selectedRecord?.record_type === "image" ? (
              <StyledImage
                src={selectedRecord?.url}
                alt={selectedRecord?.name}
              />
            ) : (
              <Worker workerUrl={workerUrl}>
                <Viewer fileUrl={selectedRecord?.url} />
              </Worker>
            ))}
        </StyledModalContent>
      </StyledModal>
    </Box>
  );
};

export default InfoSection;
